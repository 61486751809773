import { useState, useEffect } from "react";
import { Link, Routes, Route, useParams } from "react-router-dom";
import PageHeadingTitle from "./PageHeadingTitle.component";
import Card from "./Card.component";
import BlogPost from "./[slug]/BlogPost.component";

const BlogPostList = () => {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    fetch(
      `https://johnbartmann.com/creative-commons-music/wp-json/wp/v2/posts?per_page=3`
    )
      .then((response) => response.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error fetching post:", error));
  }, []);

  if (!posts) {
    return <div className="center">Loading...</div>;
  }

  return (
    <>
      <PageHeadingTitle title={"BLOG"} />
      <p style={{ marginBottom: 10 }}>
        View all previous music releases and blog posts.
      </p>

      {posts.map((post) => {
        return (
          <div key={post.id}>
            <Card title={post.title.rendered} link={post.link}></Card>

            <Routes>
              <Route path="/:blogId" element={<BlogPost postObj={posts} />} />
            </Routes>
          </div>
        );
      })}
    </>
  );
};

export default BlogPostList;
