import WaveSurfer from "wavesurfer.js";
import React, { useState, useEffect, useRef } from "react";
import { BsFillPlayFill, BsFillPauseFill, BsDownload } from "react-icons/bs";
import { PiYoutubeLogo } from "react-icons/pi";
import { PiDiceFiveFill } from "react-icons/pi";
import "../styles/AudioWaveform.styles.css";

const AudioWaveform = ({ musicTracks }) => {
  const wavesurferRef = useRef(null);
  const wavesurferObjRef = useRef(null);
  const [currentAudio, setCurrentAudio] = useState();
  const [currentTitle, setCurrentTitle] = useState(
    "Select a track from the list"
  );
  // const [currentImage, setCurrentImage] = useState(
  //   "https://source.unsplash.com/collection/1163637/35x35"
  // );
  const [playingState, setPlayingState] = useState("notStarted");
  const [isPlaying, setIsPlaying] = useState(false);
  const [loadingState, setLoadingState] = useState();

  musicTracks.sort((a, b) => b.rank - a.rank);

  useEffect(() => {
    if (currentAudio === undefined) {
      return;
    }

    const wavesurfer = WaveSurfer.create({
      container: wavesurferRef.current,
      waveColor: "white",
      progressColor: "#2665ad",
      // background: "#242424",
      responsive: true,
      height: 1,
      barGap: 0,
      barAlign: "top",
      barWidth: 1,
      // barRadius: 10,
      minPxPerSec: 1,
    });

    wavesurferObjRef.current = wavesurfer;

    wavesurfer.load(currentAudio);

    wavesurfer.once("ready", () => {
      wavesurfer.play();
    });

    wavesurfer.on("play", () => {
      setPlayingState("play");
      setCurrentTitle(currentTitle);
      setIsPlaying(true);
    });
    wavesurfer.on("pause", () => {
      setPlayingState("pause");
      setIsPlaying(false);
    });
    wavesurfer.on("finish", () => {
      setPlayingState("finish");
      setIsPlaying(false);
    });

    return () => {
      wavesurfer.destroy();
    };
  }, [currentAudio, currentTitle]);

  const handleAudioSelect = ({ musicTrack }) => {
    if (
      currentAudio ===
      `https://johnbartmann.com/track/${musicTrack.url_slug}.mp3`
    ) {
      if (playingState === "play") {
        wavesurferObjRef.current.pause();
        setIsPlaying(true);
      } else {
        wavesurferObjRef.current.play();
        console.log(wavesurferObjRef.current);
        setIsPlaying(false);
      }
      return;
    }
    setCurrentTitle(musicTrack.title);
    setCurrentAudio(
      `https://johnbartmann.com/track/${musicTrack.url_slug}-sample.mp3`
    );
    // setCurrentImage("https://source.unsplash.com/collection/1163637/35x35");
  };

  const handleAudioPlayPause = () => {
    if (!currentAudio) {
      return;
    } else {
      if (playingState === "play") {
        wavesurferObjRef.current.pause();
        setIsPlaying(true);
      } else if (playingState !== "play") {
        wavesurferObjRef.current.play();
        setIsPlaying(false);
      } else {
        console.log("Error: Unknown state: " + playingState);
      }
    }
  };

  const preventPlaybackOnExternalClick = (event) => {
    event.stopPropagation(); // Prevents the click event from reaching parent elements
    // Add any specific logic for the icon click here if needed
  };

  const selectRandom = ({ musicTracks }) => {
    let selectedTrack =
      musicTracks[Math.floor(Math.random() * musicTracks.length)];
    setCurrentAudio(
      `https://johnbartmann.com/track/${selectedTrack.url_slug}-sample.mp3`
    );
    setCurrentTitle(selectedTrack.title);
  };

  return (
    <>
      <div>
        <div className="audio-list-container">
          <div className="grid grid-cols-[220px_3fr_1fr] text-zinc-800 gap-2 font-bold pr-2 bg-zinc-200 fixed lg:grid-cols-[520px_3fr_1fr]">
            <div className="text-sm text-left">TITLE</div>
            <div className="text-sm text-left">FEEL</div>
            <div className="text-sm text-left">GET</div>
          </div>
          <div className="mt-8">
            {musicTracks.map((musicTrack) => (
              <div
                className="flex items-justify no-overflow"
                key={musicTrack.id}
              >
                <div
                  className="audio-list-item"
                  onClick={() => handleAudioSelect({ musicTrack })}
                >
                  <div className="flex justify-between">
                    <div className="grid grid-cols-[220px_6fr_1fr] gap-2 max-w-screen-lg lg:grid-cols-[520px_6fr_1fr]">
                      <div className="text-sm text-left w-full whitespace-nowrap overflow-hidden ">
                        {musicTrack.title}
                      </div>
                      <div className="text-sm text-left w-full whitespace-nowrap overflow-hidden">
                        {musicTrack.tag01}
                      </div>
                      <div className="text-sm text-left w-full whitespace-nowrap overflow-hidden ">
                        <div className="flex items-center justify-center">
                          <a
                            href={musicTrack.url_gum}
                            target="_blank"
                            rel="noopener nofollow"
                            onClick={preventPlaybackOnExternalClick}
                          >
                            <BsDownload
                              style={{ fontSize: "1rem", margin: "0 auto" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="wavesurfer-container">
              <div className="wavesurfer-img">
                <div
                  className="wavesurfer-img-overlay"
                  onClick={() => handleAudioPlayPause()}
                  // style={{
                  //   backgroundImage: `url(${currentImage})`,
                  // }}
                >
                  {!isPlaying ? (
                    <BsFillPlayFill className="text-4xl hover:scale-125 text-white" />
                  ) : (
                    <BsFillPauseFill className="text-4xl hover:scale-125 text-white" />
                  )}
                </div>
              </div>
              <div className="wavesurfer-waveform">
                <div
                  className="text-m"
                  style={{ width: "100%" }}
                  ref={wavesurferRef}
                >
                  {currentTitle}
                </div>
              </div>
              <div className="relative flex items-center text-4xl m-2 hover:scale-125">
                <span onClick={() => selectRandom({ musicTracks })}>
                  <PiDiceFiveFill />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioWaveform;
