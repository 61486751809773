import { useParams } from "react-router-dom";

const BlogPost = ({ postObj }) => {
  const { blogId } = useParams();

  return <>{postObj[1].id}</>;
  // return <>{postObj.filter((p) => p.id === blogId)}</>;
};

export default BlogPost;
