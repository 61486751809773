import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import "../styles/App.styles.scss";
import ReactGA from "react-ga4";
import Home from "./Home.component";
import Navbar from "./Navbar.component";
import Footer from "./Footer.component";
import Music from "./Music.component";
import WebDev from "./WebDev.component";
import Work from "./Work.component";
import About from "./About.component";
import Contact from "./Contact.component";
import FAQ from "./FAQ.component";
import BlogPost from "./[slug]/BlogPost.component";
import BlogPostList from "./BlogPostList.component";
import NotFound from "./NotFound.component";
import { FaEnvelope, FaWhatsapp, FaLinkedin } from "react-icons/fa";

function App() {
  ReactGA.initialize("G-0FBV9Z3D2X");

  return (
    <div className="content-container">
      <div className="overlay">
        <div className="app-container hiddenscrollbars">
          <div className="flex justify-between items-end mh-6 w-full p-2 mb-2 ">
            <a className="text-white" href="/">
              <div className="flex flex-col flex-end">JOHN BARTMANN</div>
            </a>
            <div className="flex">
              <a href="mailto:hi@johnbartmann.com" target="_blank">
                <FaEnvelope className="m-2" fontSize="1rem" />
              </a>
              <a href="https://wa.link/h4thrk" target="_blank">
                <FaWhatsapp className="m-2" fontSize="1rem" />
              </a>
              <a
                href="https://www.linkedin.com/in/johnbartmann/"
                target="_blank"
              >
                <FaLinkedin className="m-2" fontSize="1rem" />
              </a>
            </div>
          </div>
          <BrowserRouter>
            <Routes>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/work" element={<Work />} />
              <Route path="/webdev" element={<WebDev />} />
              <Route path="/music" element={<Music />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/blogpostlist//*" element={<BlogPostList />} />
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}
export default App;
