import Navbar from "./Navbar.component.js";
import PageHeadingTitle from "./PageHeadingTitle.component.js";
import devJobs from "../data/devJobs.json";
import Card from "./Card.component";

const WebDev = () => {
  return (
    <div>
      <Navbar />
      <div className="single-page-container">
        <PageHeadingTitle title={"WEB DEVELOPMENT"} margin={"mt-4"} />
        <p>
          Web application development portfolio. Tech stack: ReactJS, Redux,
          TailwindCSS, SCSS, HTML, JS, CSS
        </p>
        {devJobs.map((job) => (
          <Card
            title={job.title}
            description={job.description}
            link={job.link}
            key={job.id}
          />
        ))}
        <br />
      </div>
    </div>
  );
};

export default WebDev;
