import { useRef } from "react";
import { Link } from "react-router-dom";
import "../styles/App.styles.scss";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Navbar = () => {
  const leftChevron = useRef(null);
  const rightChevron = useRef(null);
  function goLeft() {
    leftChevron.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }
  function goRight() {
    rightChevron.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }

  return (
    <div className="navbar-container hiddenscrollbars">
      <div className="navbar-nochevron-left"></div>
      <div className="navbar-chevron-left" onMouseOver={goLeft}>
        <BsChevronLeft size={20} opacity={0.3} />
      </div>

      <div className="navbar">
        <div className="navbar-links-container hiddenscrollbars">
          <Link to="/work">
            <h1 className="btn-nav" ref={leftChevron}>
              WORK
            </h1>
          </Link>
          <Link to="/webdev">
            {" "}
            <h1 className="btn-nav">WEB DEV</h1>
          </Link>
          <Link to="/music">
            {" "}
            <h1 className="btn-nav">MUSIC</h1>
          </Link>

          <Link to="/about">
            <h1 className="btn-nav">ABOUT</h1>
          </Link>
          <Link ref={rightChevron} to="/contact">
            {" "}
            <h1 className="btn-nav">CONTACT</h1>
          </Link>
        </div>
      </div>

      {/* <div className="navbar-scroll-anchor-right"></div> */}

      <div className="navbar-chevron-right" onMouseOver={goRight}>
        <BsChevronRight size={20} opacity={0.3} />
      </div>
      <div className="navbar-nochevron-right"></div>
    </div>
  );
};
export default Navbar;
